<template>
  <el-select ref="remoteSelect" v-bind="$attrs" size="mini" filterable v-model="selModel" v-loading="loading"
    v-select-load="downLoad" no-data-text="无可选项" :placeholder="option.placeholder || $attrs.placeholder"
    :popper-class="`load-local local-${option.type || ''}`" element-loading-text=" 加载中"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255,1)"
    :collapse-tags="option.collapseTags || false" :filter-method="debounceMethod"
    :reserve-keyword="option.reserveKeyword || true" :multiple-limit="option.multipleLimit || 0"
    :clearable="option.clearable || $attrs.clearable||true" :value-key="option.valueKey"
    :class="['sys-local', loading ? 'local-hide' : '']" :disabled="loading || option.disabled || $attrs.disabled"
    @change="change" @visible-change="visibleChange" :sunyunSelect="sunyunSelect" @keydown.native="handleSelectKeydown($event)">
    <div class="static" v-if="option.labels">
      <span class="headTitle" v-for="(item, i) in option.labels" :key="`${item.title}${i}`" :style="`min-width: ${item.width || '150'}px;width: ${
          item.width || '150'
        }px;max-width: ${item.width || '150'}px;`">
        {{ item.title }}
      </span>
    </div>
    <el-option v-if="downLoading" disabled label="加载中..." value="v@loading@^" :style="loadStyle" />
    <el-option v-else-if="option.labels && !(data && data.length)" label="无可选项" value="v@404@^" :style="showStyle"
      disabled />
    <template v-else>
      <el-checkbox-group v-if="multiple" v-model="selModel">
        <el-option v-for="(obj, i) in data.slice(0, addNum)" :key="`${obj[keyName]}.${i}`" :label="obj[option.label]"
          :disabled="obj.disabled" :value="isValuekey ? obj : obj[keyName]">
          <div :class="['label']">
            <el-checkbox style="pointer-events: none" :label="isValuekey ? obj : obj[keyName]">{{
            }}</el-checkbox>
            <template v-if="option.labels">
              <span class="headTitle" v-for="(item, index) in option.labels" :key="`${item.label}${index}`">
                {{ obj[item.label] }}
              </span>
            </template>
            <span v-else class="headTitle">
              {{ obj[option.label] }}
            </span>
          </div>
        </el-option>
      </el-checkbox-group>
      <template v-else>
        <el-option v-for="(obj, i) in data.slice(0, addNum)" :key="`${obj[keyName]}.${i}`" :label="obj[option.label]"
          :disabled="obj.disabled" :value="isValuekey ? obj : obj[keyName]">
          <div :class="['label']" v-if="option.labels">
            <span class="headTitle" v-for="(item, index) in option.labels" :key="`${item.label}${index}`">
              {{ obj[item.label] }}
            </span>
          </div>
        </el-option>
      </template>
    </template>
    <div class="static static-bottom" v-if="buttons">
      <div class="content" v-for="(item, i) in buttons" :key="`${item.title}${i}`">
        <el-link :icon="item.icon" @click="handleEvent(item)">
          {{ item.title }}
        </el-link>
      </div>
    </div>
  </el-select>
</template>
<script>
import { directives, deepCopy, isArray, haveContent } from "@/utils";
import { mixin } from "@/components/tablePage/select/mixin/mixin-select.js";
/**
 * option 配置（还支持 全部element select 组件支持的属性）
 * 1   data        [Array]                         列表数据
 * 2   remote      [Function]                      请求列表数据接口（ remote、data 二选一生效，remote 优先级高）
 * 2.1 remoteBody  [Object, String, Array, Number] 请求内容
 * 2.2 dataKey     [String]                        返回内容的取值 key（返回格式得是 { code, ...other }）
 * 2.3 openRemote  [Boolean]                       打开选择项时是否拉去新的数据
 * 3   downLoad    [Number]                        分页每页显示数据（默认值 15 ）
 * 4   valueKey    [String]                        Object Key 值（v-model 为 Object ）
 * 5   value       [String]                        Object Key 值（v-model 为该值）
 * 6   labels      [Array]                         表格页面展示多字段
 * 6.1 { title: "", label: "" } title：标题  label：label 对应得 key 字段
 * 7   buttons     [Array]                         底部按钮 （新增、显示更多等）
 * 7.1 { title: "", type: "", click：'' } title：标题  type: add 新增 more 显示更多 点击事件类型 默认 click  handleEvent 事件返回
 * 8   change      [Function]                      选择事件
 * other 等一些 el-select 支持属性
 * filterable  [Boolean]                       是否支持搜索
 * clearable   [Boolean]                       是否支持删除
 * disabled    [Boolean]                       是否禁用
 *
 * 事件
 * handleEvent 一些点击事件支持 例如 buttons 配置的click
 * change 选择事件 同 option.change
 */
export default {
  name: "ComponentSelectLocal",
  mixins: [mixin],
  directives: { "select-load": directives("load-local") },
  data() {
    return {
      visible: false, // 是否打开下拉框
      data: [], // 显示数据
      optionData: null, // 全部数据
      downLoading: false,
      loading: true, // 加载中
      addNum: undefined, // 加载数
      loadStyle: {
        height: "34px",
        width: "300px",
        "line-height": "34px",
        "text-align": "left",
        color: "#999",
        cursor: "text",
      },
      showStyle: {
        height: "34px",
        "line-height": "34px",
        "text-align": "center",
        color: "#999",
        cursor: "text",
      },
    };
  },
  computed: {
    buttons() {
      const defaultButton = {
        add: {
          icon: "el-icon-plus",
          title: "新增",
        },
        more: {
          icon: "el-icon-more",
          title: "显示更多",
        },
      };
      return this.option.buttons?.map?.((x) => ({
        ...x,
        ...defaultButton[x.type],
      }));
    },
    sunyunSelect() {
      const item = this.buttons?.find?.((x) => x.type === "more");
      return {
        suffix: item?.icon || "",
        click: item ? () => this.handleEvent(item) : undefined,
      };
    },
    // 每页显示数
    downLoadNum() {
      const { downLoad } = this.option;
      return downLoad && Object.prototype.toString.call(downLoad) === "[object Number]"
        ? downLoad
        : 15;
    },
  },
  watch: {
    row: {
      handler(val, oldValue) {
        if (
          !this.option.remote &&
          JSON.stringify(val) !== JSON.stringify(oldValue) &&
          this.option.rowDataKey
        ) {
          if (typeof this.option.rowDataKey === "function") {
            this.optionData = this.option.rowDataKey(this.row);
          } else {
            this.optionData = this.row[this.option.rowDataKey];
          }
          this.loadMote();
        }
      },
      immediate: true,
      deep: true,
    },
    "option.data": {
      handler(val, oldValue) {
        if (!this.option.remote && JSON.stringify(val) !== JSON.stringify(oldValue)) {
          this.optionData = val;
          this.loadMote();
        }
      },
      immediate: true,
      deep: true,
    },
    "option.remote": {
      handler() {
        if (typeof this.option.remote === "function" && !this.optionData) {
          this.loadRequest(true);
        }
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(val) {
        this.loadMote();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 初始化 回显
    async loadMote() {
      if (!this.visible) {
        if (
          haveContent(this.value) &&
          isArray(this.optionData) &&
          this.optionData?.length
        ) {
          this.loading = true;
          this.data = deepCopy(this.optionData).filter((x) => this.isModel(x));
          this.addNum = this.data?.length;
          await this.$nextTick();
          this.data = [];
          this.addNum = 0;
        }
        this.loading = false;
      }
    },
    async loadRequest(init = false) {
      let data = [];
      try {
        if (this.option.showItem) {
          data = this.option.showItem
        } else {
          const dataKey = this.option.dataKey || "data";
          const remoteBody =
            typeof this.option.remoteBody === "function"
              ? this.option.remoteBody()
              : this.option.remoteBody;
          const res = await this.option.remote(remoteBody || {});
          if (typeof dataKey === "function") {
            data = dataKey(res);
          } else {
            data = res[dataKey];
          }
        }
      } catch (error) {
        console.log("loadRequest err-", error);
      }
      this.optionData = deepCopy(this.option.format ? this.option.format(data) : data);
      await this.$nextTick();
      if (init) {
        this.loadMote();
      }
    },
    // 是否等于选中值
    isModel(x) {
      const selModel = this.multiple
        ? this.selModel?.map((x) => (this.isValuekey ? x[this.keyName] : x))
        : [this.isValuekey ? this.selModel[this.keyName] : this.selModel];
      return selModel.includes(x[this.keyName]);
    },
    // 下拉加载
    downLoad() {
      this.addNum = this.option.downLoad ? this.addNum + this.downLoadNum : undefined;
    },
    // 模糊查询搜索
    async remoteMethod(val = "") {
      await this.$nextTick();
      this.addNum = this.option.downLoad ? this.downLoadNum : undefined;
      let data = deepCopy(this.optionData);
      const labels = this.option.labels
        ? this.option.labels.map((x) => x.label)
        : [this.option.label];
      const isContained = (a, b) => {
        // a和b其中一个不是数组，直接返回false
        if (!(a instanceof Array) || !(b instanceof Array)) return false;
        const len = b?.length;
        // a的长度小于b的长度，直接返回false
        if (a?.length < len) return false;
        for (let i = 0; i < len; i++) {
          // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
          let index = a.indexOf(b[i]);
          if (index === -1) {
            return false;
          } else {
            a.splice(index, 1);
          }
        }
        // 遍历结束，返回true
        return true;
      };
      const clearSpace = (str) => {
        return str ? String(str).replace(/\s*/g, "") : "";
      };
      let inputVal = clearSpace(val);
      data = !clearSpace(inputVal)
        ? deepCopy(this.optionData)
        : deepCopy(this.optionData).filter((x) => {
          let find = false;
          for (let index = 0; index < labels?.length; index++) {
            const element = labels[index];
            if (
              isContained(
                clearSpace(x[element]).split(""),
                clearSpace(inputVal).split("")
              )
            ) {
              find = true;
              break;
            }
          }
          return find;
        }); // 查询搜索数据
      // data = data.filter(x => x[this.option.label].includes(val)); // 查询搜索数据
      if (this.haveModel && this.option.selectTtop) {
        const haead = [];
        const body = [];
        data.forEach((x) => {
          if (this.isModel(x)) {
            haead.push(x); // 选中 内容置顶
          } else {
            body.push(x);
          }
        });
        data = [...haead, ...body];
      }
      this.downLoading = false;
      this.data = data;
    },
    async visibleChange(visible) {
      this.visible = visible;
      if (visible) {
        if (this.option.openRemote && typeof this.option.remote === "function") {
          this.downLoading = true;
          this.optionData = [];
          this.data = [];
          await this.loadRequest();
        }
        this.remoteMethod();
      } else {
        this.addNum = 0; // hide select dom
      }
    },
    handleSelectKeydown(e){
      this.$emit('handleSelectKeydown',e);
    },
    change(val) {
      if (this.multiple) {
        this.$nextTick(() => {
          const tags = this.$refs.remoteSelect.$el.querySelector(".el-select__tags span");
          tags.scrollTo({ left: tags.scrollWidth, behavior: "smooth" });
        });
      }
      const obj = this.getRadioData(val);
      if (typeof this.option.change === "function") {
        this.option.change(val, obj);
      }
      this.$emit("selectChange", val, obj);
    },
    handleEvent(item) {
      if (!item.click || item.click === "click") {
        this.$emit("handleEvent", item.type);
      } else {
        // 组件内的一些时间
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.local-hide {
  ::v-deep .el-select__tags {
    visibility: hidden;
  }

  ::v-deep .el-input__inner {
    color: #f5f7fa;
  }
}

.sys-local {
  width: 100%;
  ::v-deep .el-icon-more {
    cursor: pointer;
    &:hover {
      color: #409eff !important;
    }
  }
  ::v-deep .el-select__tags {
    left: 2px;
    & span:not(.el-tag, .el-select__tags-text):first-child {
      display: flex;
      display: flex;
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      max-width: calc(100% - 55px) !important;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  ::v-deep .el-loading-mask {
    border: 1px solid #dcdfe6;
    border-radius: 4px;

    .el-loading-spinner {
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      margin: 0;

      .el-icon-loading,
      .el-loading-text {
        color: #555555;
        margin-left: 10px;
      }
    }
  }
}

.load-local {
  &.is-multiple {
    padding-right: 20px;

    .static,
    .static-bottom {
      z-index: 10;
      padding-right: 20px;
      .content {
        text-align: center;
        flex-grow: 1;
      }
    }
  }

  .static {
    opacity: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgba($color: #f3f4f5, $alpha: 1);
    border-top: 6px solid #ffffff;
    height: 38px;
    width: 100%;
    padding: 0 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      min-width: 90px;
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      color: #606266;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
    }
  }
  .static-bottom {
    bottom: 0;
    z-index: 2;
    border-top: solid 1px #dfe4ed;
    background-color: #ffffff;
  }

  .selected .label .headTitle {
    color: #1890ff !important;
  }

  .el-select-dropdown__item {
    padding-right: 20px;
  }

  .el-select-dropdown__item.hover {
    background-color: #f8f8f9;
  }

  .el-select-dropdown__item.is-disabled {
    background: #f4f5f6;
    opacity: 0.75;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      flex: 1;
      font-size: 14px;
      color: #606266;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
    }
  }
}
</style>
